import React, { useState } from "react"

import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { AnimatePresence, motion } from "framer-motion"

import { ContentfulFaqEntriesProps } from "../../../hooks/useLandingContentfulQuery"
import { CircleAndArrow } from "../../Icon/CircleAndArrow"

type FaqsProps = {
  faqs: ContentfulFaqEntriesProps
  className?: string
}

type AccordionProps = {
  title: string
  children: React.ReactNode
  i: number
  expanded: boolean | number
  setExpanded: (value: any) => void
  className?: string
}

const Accordion = (props: AccordionProps) => {
  const { title, children, i, expanded, setExpanded, className } = props
  const isOpen = i === expanded

  return (
    <motion.div
      initial={false}
      onClick={() => setExpanded(isOpen ? false : i)}
      className={
        "faq-accordian " + (isOpen ? "open" : "close") + " " + className
      }
    >
      <div className={`faq-accordian__question`}>
        <h4 className={"question"}>{title}</h4>
        <CircleAndArrow isOpen={isOpen} />
      </div>
      <AnimatePresence initial={false}>
        {isOpen && (
          <motion.div
            className="faq-accordian__answer"
            key="content"
            initial="collapsed"
            animate="open"
            exit="collapsed"
            variants={{
              open: { opacity: 1, height: "auto", y: 0 },
              collapsed: { opacity: 0, height: 0, y: -60 },
            }}
            transition={{
              duration: 1.2,
              ease: [0.16, 1, 0.3, 1],
              opacity: { duration: 0.4, ease: [0.22, 1, 0.36, 1] },
            }}
          >
            <p className="answer__text">{children}</p>
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  )
}

const FaqList = (props: FaqsProps) => {
  const [expanded, setExpanded] = useState(false)

  return (
    <>
      {props.faqs.list.map((faq, index) => (
        <Accordion
          title={faq.question.text}
          i={index}
          key={index}
          expanded={expanded}
          setExpanded={setExpanded}
          className={props.className}
        >
          {documentToReactComponents(JSON.parse(faq.answer.raw))}
        </Accordion>
      ))}
    </>
  )
}

export default React.memo(FaqList)
